/**
 * MW Design System - Base Colors
 * https://mercury.design/system?p=color
 * https://www.figma.com/file/S7Q78jkg0O92CodjYielQO/%F0%9F%92%BB-Design-System---Web?node-id=133%3A550
 */
import {recordKeys} from '~/utils/recordKeys'

/**
 * The semantic color layer that extends our base color palette.
 */
export type SemanticColorName =
  // Background
  | 'background-default'
  | 'background-default-hovered'
  | 'background-primary'
  | 'background-primary-hovered'
  | 'background-secondary'
  | 'background-secondary-hovered'
  | 'background-secondary-high-contrast'
  | 'background-emphasized'
  | 'background-emphasized-hovered'
  | 'background-destructive'
  | 'background-destructive-hovered'
  | 'background-inverted'
  | 'background-success'
  | 'background-error'
  | 'background-foyer-glass'
  | 'background-input-focused'
  | 'background-input-disabled'
  | 'background-input-checked'
  | 'background-input-checked-hovered'
  | 'background-input-checked-disabled'
  | 'background-role-admin'
  | 'background-role-bookkeeper'
  | 'background-role-card-only'
  | 'background-role-custom'
  // Text
  | 'text-default'
  | 'text-secondary'
  | 'text-tertiary'
  | 'text-emphasized'
  | 'text-disabled'
  | 'text-placeholder'
  | 'text-focused'
  | 'text-title'
  | 'text-error'
  | 'text-success'
  | 'text-link'
  | 'text-link-hovered'
  | 'text-money'
  | 'text-money-pending-or-null'
  | 'text-money-out'
  | 'text-money-in'
  | 'text-on-primary'
  | 'text-on-secondary'
  | 'text-on-secondary-hovered'
  | 'text-on-inverted'
  | 'text-on-inverted-secondary'
  | 'text-on-emphasized'
  | 'text-on-emphasized-hovered'
  | 'text-role-admin'
  | 'text-role-bookkeeper'
  | 'text-role-card-only'
  | 'text-role-custom'
  // Icon
  | 'icon-default'
  | 'icon-primary'
  | 'icon-primary-hovered'
  | 'icon-secondary'
  | 'icon-tertiary'
  | 'icon-emphasized'
  | 'icon-success'
  | 'icon-error'
  | 'icon-money-in'
  | 'icon-money-out'
  | 'icon-on-primary'
  | 'icon-on-secondary'
  | 'icon-on-secondary-hovered'
  | 'icon-on-tertiary'
  | 'icon-on-tertiary-hovered'
  | 'icon-on-inverted'
  | 'icon-on-inverted-success'
  | 'icon-on-inverted-error'
  // Border
  | 'border-default'
  | 'border-emphasized'
  | 'border-error'
  | 'border-on-inverted'
  | 'border-input'
  | 'border-input-emphasized'
  | 'border-input-focused'
  | 'border-input-error'
  // Data Visualization
  | 'data-visualization-line'
  | 'data-visualization-line-primary'
  | 'data-visualization-line-marker'
  | 'data-visualization-axis-line'
  | 'data-visualization-tick-line'
  | 'data-visualization-tick-line-minor'
  | 'data-visualization-thermometer'

/**
 * An array containing all of the `SemanticColorNames`.
 * This allows iterating through all the colors, e.g. in Storybook
 */
export const semanticColorNames = recordKeys<SemanticColorName>({
  'background-default': true,
  'background-default-hovered': true,
  'background-primary': true,
  'background-primary-hovered': true,
  'background-secondary': true,
  'background-secondary-hovered': true,
  'background-secondary-high-contrast': true,
  'background-emphasized': true,
  'background-emphasized-hovered': true,
  'background-destructive': true,
  'background-destructive-hovered': true,
  'background-inverted': true,
  'background-success': true,
  'background-error': true,
  'background-foyer-glass': true,
  'background-input-focused': true,
  'background-input-disabled': true,
  'background-input-checked': true,
  'background-input-checked-hovered': true,
  'background-input-checked-disabled': true,
  'background-role-admin': true,
  'background-role-bookkeeper': true,
  'background-role-card-only': true,
  'background-role-custom': true,
  // Text
  'text-default': true,
  'text-secondary': true,
  'text-tertiary': true,
  'text-emphasized': true,
  'text-disabled': true,
  'text-placeholder': true,
  'text-focused': true,
  'text-title': true,
  'text-error': true,
  'text-success': true,
  'text-link': true,
  'text-link-hovered': true,
  'text-money': true,
  'text-money-pending-or-null': true,
  'text-money-out': true,
  'text-money-in': true,
  'text-on-primary': true,
  'text-on-secondary': true,
  'text-on-secondary-hovered': true,
  'text-on-inverted': true,
  'text-on-inverted-secondary': true,
  'text-on-emphasized': true,
  'text-on-emphasized-hovered': true,
  'text-role-admin': true,
  'text-role-bookkeeper': true,
  'text-role-card-only': true,
  'text-role-custom': true,
  // Icon
  'icon-default': true,
  'icon-primary': true,
  'icon-primary-hovered': true,
  'icon-secondary': true,
  'icon-tertiary': true,
  'icon-emphasized': true,
  'icon-success': true,
  'icon-error': true,
  'icon-money-in': true,
  'icon-money-out': true,
  'icon-on-primary': true,
  'icon-on-secondary': true,
  'icon-on-secondary-hovered': true,
  'icon-on-tertiary': true,
  'icon-on-tertiary-hovered': true,
  'icon-on-inverted': true,
  'icon-on-inverted-success': true,
  'icon-on-inverted-error': true,
  // Border
  'border-default': true,
  'border-emphasized': true,
  'border-error': true,
  'border-on-inverted': true,
  'border-input': true,
  'border-input-emphasized': true,
  'border-input-focused': true,
  'border-input-error': true,
  // Data Visualization
  'data-visualization-line': true,
  'data-visualization-line-primary': true,
  'data-visualization-line-marker': true,
  'data-visualization-axis-line': true,
  'data-visualization-tick-line': true,
  'data-visualization-tick-line-minor': true,
  'data-visualization-thermometer': true,
})
