/**
 * MW Design System - FEM Semantic Colors
 * https://mercury.design/system?p=color
 * https://www.figma.com/file/S7Q78jkg0O92CodjYielQO/%F0%9F%92%BB-Design-System---Web?node-id=133%3A550
 */

import {recordKeys} from '~/utils/recordKeys'

export type FemSemanticColorName = 'fem-primary' | 'fem-black'

/**
 * An array containing all of the `FemColorNames`.
 * This allows iterating through all the colors, e.g. in Storybook
 */
export const femSemanticColorNames = recordKeys<FemSemanticColorName>({
  'fem-primary': true,
  'fem-black': true,
})
