/**
 * MW Design System - Base Colors
 * https://mercury.design/system?p=color
 * https://www.figma.com/file/S7Q78jkg0O92CodjYielQO/%F0%9F%92%BB-Design-System---Web?node-id=133%3A550
 */
import {recordKeys} from '~/utils/recordKeys'

/**
 * The base color palette that serves as the foundation of our semantic color layer.
 */
export type BaseColorName =
  | 'grey'
  | 'grey-medium'
  | 'grey-dark'
  | 'black'
  | 'white'
  | 'off-white'
  | 'off-white-dark'
  | 'pearl-grey'
  | 'pearl-grey-medium'
  | 'pearl-grey-dark'
  | 'pearl-grey-darkest'
  | 'blue-light'
  | 'blue'
  | 'blue-dark'
  | 'red-light'
  | 'red-medium'
  | 'red'
  | 'red-dark'
  | 'green-light'
  | 'green'
  | 'green-dark'
  | 'highlight-light'
  | 'highlight'
  | 'highlight-dark'
  | 'admin-light'
  | 'admin-dark'
  | 'custom-light'
  | 'custom-dark'
  | 'bookkeeper-light'
  | 'bookkeeper-dark'
  | 'card-only-light'
  | 'card-only-dark'
  | 'mauve-light'
  | 'mauve-dark'
  | 'deep-blue-light'
  | 'deep-blue-dark'
  | 'inherit'
  | 'newFEM'

/**
 * An array containing all of the `BaseColorNames`.
 * This allows iterating through all the colors, e.g. in Storybook
 */
export const baseColorNames = recordKeys<BaseColorName>({
  'grey': true,
  'grey-medium': true,
  'grey-dark': true,
  'black': true,
  'white': true,
  'off-white': true,
  'off-white-dark': true,
  'pearl-grey': true,
  'pearl-grey-medium': true,
  'pearl-grey-dark': true,
  'pearl-grey-darkest': true,
  'blue-light': true,
  'blue': true,
  'blue-dark': true,
  'green-light': true,
  'green': true,
  'green-dark': true,
  'red-light': true,
  'red-medium': true,
  'red': true,
  'red-dark': true,
  'highlight-light': true,
  'highlight': true,
  'highlight-dark': true,
  'admin-light': true,
  'admin-dark': true,
  'custom-light': true,
  'custom-dark': true,
  'bookkeeper-light': true,
  'bookkeeper-dark': true,
  'card-only-light': true,
  'card-only-dark': true,
  'mauve-light': true,
  'mauve-dark': true,
  'deep-blue-light': true,
  'deep-blue-dark': true,
  'inherit': true,
  'newFEM': true,
})
