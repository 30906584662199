import * as R from 'ramda'
import * as SharedVariables from '~/mercuryWebCompat/variables.js'
import styles from './styles.module.css'
import {type BaseColorName, baseColorNames} from './baseColors'
import {type FemSemanticColorName, femSemanticColorNames} from './femSemanticColors'
import {type SemanticColorName, semanticColorNames} from './semanticColors'

const colorVariables = {
  ...SharedVariables.baseColors,
  ...SharedVariables.semanticColors,
} as const

type ColorName = BaseColorName | SemanticColorName | FemSemanticColorName

const allColorNames: ColorName[] = [
  ...baseColorNames,
  ...semanticColorNames,
  ...femSemanticColorNames,
]

/**
 * Safely cast from a `string` to a `BaseColorName`. If the provided `string` is not a `BaseColorName`, this will throw.
 * Useful if e.g. a user is selecting a color from a UI input in storybook
 */
// eslint-disable-next-line import/no-unused-modules
export const stringToColorNameThrows = (s: string): BaseColorName => {
  if (R.includes(s, allColorNames)) {
    return s as BaseColorName
  } else {
    throw new Error(`Invalid string for converting to a ColorName: ${s}`)
  }
}

/**
 * Converts a `BaseColorName` or `SemanticColorName` into a CSS className that applies it as a foreground color.
 *
 * @remarks
 * The class you'll get will look something like this:
 * ```CSS
 * .blue {
 *   color: var(--ds-blue);
 * }
 * ```
 */
export const colorNameToColorClass = (name: ColorName): string => {
  return styles[name]
}

/**
 * Converts a `BaseColorName` or `SemanticColorName` into a CSS className that applies it as a background color.
 *
 * @remarks
 * The class you'll get will look something like this:
 * ```CSS
 * .background-blue {
 *   background-color: var(--ds-blue);
 * }
 * ```
 */
export const colorNameToBackgroundColorClass = (name: ColorName): string => {
  return styles[`background-${name}`]
}

/**
 * Converts a `BaseColorName` or `SemanticColorName` into its hex value.
 */
export const colorNameToHex = (name: ColorName): string => {
  const color = colorVariables[`--ds-${name}` as keyof typeof colorVariables]
  if (color) {
    return color
  } else {
    throw new Error(
      `Invalid ColorName: ${name}. Check that the color name is correct and that it is in the shared-variables file.`
    )
  }
}

/**
 * Converts a color className into a human-readable string.
 * Used in the Color Stories
 */
export const colorNameAsTitle = (colorClassName: string) => {
  const arr = colorClassName.split('-')
  const capital = arr.map(
    (item: string) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
  )
  const capitalString = capital.join(' ')
  return capitalString
}
